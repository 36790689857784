import VisitorTracker from '@ffn/visitor-tracking-client';

(function() {
  'use strict';

  window.onload = () => {
    let experimentIds = [],
      variationIds = [];

    const variationMap =
        window.optimizely !== undefined
          ? window.optimizely.get('state').getVariationMap()
          : undefined,
      env = 'production',
      utms = JSON.parse(sessionStorage.getItem('utm_params'));

    if (undefined !== variationMap) {
      for (const expId in variationMap) {
        const experimentData = variationMap[expId];

        experimentIds.push(expId);
        variationIds.push(experimentData.id);
      }
    }

    let vt = new VisitorTracker();
    vt.init(
      {
        experiment_id: experimentIds,
        variation_id: variationIds,
        utm_source: utms.utm_source
      },
      env,
      function() {
        let currentPage = vt.defineEvent('pageview', {}, null);
        vt.trackEvent(currentPage, {});
      }
    );
  };
})();
